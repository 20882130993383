import React, { FC, PropsWithChildren, ReactNode, Suspense } from 'react';
import { Navigate, Route, Routes as ReactRoutes, To } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { HttpError } from '@core/http';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { AdminUserRole } from '@modules/users/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/profile/model';

export const Routes: FC<PropsWithChildren> = ({ children }) => (
  <Suspense fallback={<DebouncedLineLoader />}>
    <ReactRoutes>
      {children}
      <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
    </ReactRoutes>
  </Suspense>
);

interface RestrictedRoutesProps<T extends AdminUserRole> {
  roles: Array<T>;
  redirect?: To;
  children: ReactNode | ((profile: Profile<T>) => ReactNode);
}

export function RestrictedRoute<T extends AdminUserRole>({
  roles,
  redirect = '/',
  children,
}: RestrictedRoutesProps<T>) {
  const { profile } = useAuthContext();

  return pipe(
    profile,
    O.filter((profile): profile is Profile<T> => roles.some(role => role === profile.role)),
    O.fold(
      () => <Navigate to={redirect} replace />,
      profile => <>{typeof children === 'function' ? children(profile) : children}</>,
    ),
  );
}
