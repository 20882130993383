import { httpService, HttpTask } from '@core/http';
import { CustomerCompany, Profile, UpdateCustomerCompanyParams, UpdateProfileParams } from '@modules/profile/model';
import { UpdateEmailParams } from '@shared/components/modals/email/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { AdminUserRole } from '@modules/users/model';

const URI = '/profile';

export function getProfile(): HttpTask<Profile> {
  return pipe(
    httpService.get<Profile>(URI),
    TE.chain(profile => {
      if (profile.role === AdminUserRole.Customer) {
        return pipe(
          getCustomerCompany(),
          TE.map<CustomerCompany, Profile>(company => ({
            ...profile,
            role: AdminUserRole.Customer,
            company,
          })),
        );
      } else {
        return TE.right(profile);
      }
    }),
  );
}

export function updateProfile(params: UpdateProfileParams): HttpTask<Profile> {
  return httpService.post(URI, params);
}

export function updateProfileEmail(params: UpdateEmailParams): HttpTask<Profile> {
  return httpService.post(`${URI}/email`, params);
}

export function updateProfilePassword(): HttpTask {
  return httpService.post(`${URI}/password`);
}

export function getCustomerCompany(): HttpTask<CustomerCompany> {
  return httpService.get(`${URI}/customer`);
}

export function updateCustomerCompany(params: UpdateCustomerCompanyParams): HttpTask<CustomerCompany> {
  return httpService.post(`${URI}/customer`, params);
}
