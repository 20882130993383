import { Profile } from '@modules/profile/model';
import { Predicate } from 'fp-ts/Predicate';
import { AdminUserRole } from '@modules/users/model';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export function userRolePredicate(...roles: Array<AdminUserRole>): Predicate<Profile> {
  return profile => roles.includes(profile.role);
}

export function userRolePredicateOpt(...roles: Array<AdminUserRole>): Predicate<O.Option<Profile>> {
  return profile => pipe(profile, O.exists(userRolePredicate(...roles)));
}
