import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpResult, HttpStatusCode, HttpTask } from '@core/http/model';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from '@shared/modules/range';
import { logSentryHttpError } from '@shared/modules/sentry/utils';
import { removeEmptyString } from '@shared/utils/string';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as EI from 'fp-ts/Either';
import * as T from 'fp-ts/Task';

import * as Retry from 'retry-ts';
import { retrying } from 'retry-ts/Task';
import { hideApiDownIndicator, showApiDownIndicator } from '@core/http/components/ApiDownIndicator';

function sendRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  const onError = (err: unknown, status: Retry.RetryStatus): HttpError<E> => {
    const error = HttpError.fromAxiosError<E>(err as any);

    error.log();

    if (
      status.iterNumber === 0 &&
      error.status >= 400 &&
      ![
        HttpStatusCode.UNAUTHORIZED,
        HttpStatusCode.FORBIDDEN,
        HttpStatusCode.NOT_FOUND,
        HttpStatusCode.CONFLICT,
      ].includes(error.status)
    ) {
      logSentryHttpError(`[http] error ${error.status} on ${error.url} path`, error);
    }

    if (error.isDownError()) {
      showApiDownIndicator();
    }

    return error;
  };

  const transformRequest = (status: Retry.RetryStatus) =>
    pipe(
      TE.tryCatch(request, err => onError(err, status)),
      TE.map(res => res.data),
    );

  const shouldRetry = (res: HttpResult<R, E>) =>
    pipe(
      EI.swap(res),
      EI.exists(err => err.isDownError()),
    );

  return pipe(
    retrying(Retry.capDelay(2000, Retry.exponentialBackoff(500)), transformRequest, shouldRetry),
    T.chainFirstIOK(() => hideApiDownIndicator),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return sendRequest(() => defaultAxiosInstance.get(url, config));
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  cursor: RangeCursor,
  filter?: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...cursor,
        ...filter,
      },
    }),
    TE.map(Range.fromRangeResult),
  );
}

function filterBody(data: any) {
  return pipe(
    O.fromNullable(data),
    O.filter(data => !(data instanceof FormData)),
    O.map(removeEmptyString),
    O.getOrElse(() => data),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  return sendRequest(() => defaultAxiosInstance.post(url, filterBody(data), config));
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  return sendRequest(() => defaultAxiosInstance.put(url, filterBody(data), config));
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return sendRequest(() => defaultAxiosInstance.delete(url, config));
}

export const httpService = {
  get,
  getRange,
  post,
  put,
  delete: del,
};
