import { Newtype } from 'newtype-ts';
import { SearchFilter } from '@shared/modules/filter';
import { z } from 'zod';
import { emailZodSchema } from '@shared/schemas';
import * as NEA from 'fp-ts/NonEmptyArray';
import { CustomerSearchItem } from '@modules/customers/model';
import { DefaultMantineColor } from '@mantine/core';

export type AdminUserId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum AdminUserRole {
  Admin = 'admin',
  HotLiner = 'hotliner',
  Customer = 'customer',
}

export const adminUserRoleLabel: Record<AdminUserRole, string> = {
  [AdminUserRole.Admin]: 'Administrateur',
  [AdminUserRole.HotLiner]: 'Hotliner',
  [AdminUserRole.Customer]: 'Client',
};

export enum UserStatus {
  Activated = 'activated',
  Waiting = 'waiting',
}

export const userStatusLabel: Record<UserStatus, string> = {
  [UserStatus.Activated]: 'Activé',
  [UserStatus.Waiting]: 'En attente',
};

export const userStatusColor: Record<UserStatus, DefaultMantineColor> = {
  [UserStatus.Activated]: 'blue',
  [UserStatus.Waiting]: 'gray',
};

export interface AdminUserRangeItem {
  id: AdminUserId;
  name: string | null;
  email: string;
  role: AdminUserRole;
  status: UserStatus;
}

export interface AdminUserDetail {
  id: AdminUserId;
  lastName: string | null;
  firstName: string | null;
  email: string;
  role: AdminUserRole;
  status: UserStatus;
  customer: CustomerSearchItem | null;
}

export interface AdminUserRangeFilter extends SearchFilter {
  roles: NEA.NonEmptyArray<AdminUserRole> | null;
  status: UserStatus | null;
}

export const defaultAdminUserRangeFilter: AdminUserRangeFilter = {
  search: null,
  roles: null,
  status: null,
};

export const createAdminUserSchema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: emailZodSchema,
    role: z.nativeEnum(AdminUserRole),
    customerId: z.string().uuid().nullish(),
  })
  .refine(({ role, customerId }) => role !== AdminUserRole.Customer || !!customerId, { path: ['customerId'] });

export type CreateAdminUserParams = z.infer<typeof createAdminUserSchema>;
export type UpdateAdminUserParams = Omit<CreateAdminUserParams, 'email'>;

export namespace User {
  export const authorizationsSchema = z.object({
    alerts: z.boolean(),
  });

  export type Authorizations = z.infer<typeof authorizationsSchema>;
}
