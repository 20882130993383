import * as O from 'fp-ts/Option';

const pattern =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export function isValidUrl(value: string | null | undefined): boolean {
  if (typeof value === 'string') {
    return pattern.test(value);
  }

  return false;
}

export function appendProtocol(url: string) {
  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}

export function parseUrl(url: string): O.Option<URL> {
  const urlWithProtocol = appendProtocol(url);

  if (!isValidUrl(urlWithProtocol)) {
    return O.none;
  }

  try {
    const parsed = new URL(urlWithProtocol);

    return O.some(parsed);
  } catch (e) {
    return O.none;
  }
}
