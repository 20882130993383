import React, { FC, useLayoutEffect, useState } from 'react';
import { Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { constVoid } from 'fp-ts/function';

const API_DOWN_EVENT_NAME = 'api-down';
const API_UP_EVENT_NAME = 'api-up';

const ApiDownIndicator: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(API_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(API_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(API_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(API_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return (
    <Modal size="md" opened={opened} onClose={constVoid} withCloseButton={false} centered>
      <Stack p="md" align="center">
        <Title size="h3" align="center">
          Connexion avec le serveur interrompue
        </Title>

        <Loader size="lg" />

        <Text align="center">Veuillez patienter nous essayons de rétablir la connexion.</Text>
      </Stack>
    </Modal>
  );
};

export function showApiDownIndicator() {
  window.dispatchEvent(new Event(API_DOWN_EVENT_NAME));
}

export function hideApiDownIndicator() {
  window.dispatchEvent(new Event(API_UP_EVENT_NAME));
}

export default ApiDownIndicator;
