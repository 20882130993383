import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import 'dayjs/locale/fr';

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Open Sans, sans-serif',
        primaryColor: 'teal',
        primaryShade: 9,
        colors: {
          blue: [
            '#e6fcf5',
            '#d0ebff',
            '#a5d8ff',
            '#74c0fc',
            '#4dabf7',
            '#20c997',
            '#12b886',
            '#1c7ed6',
            '#1971c2',
            '#1864ab',
          ],
          teal: [
            '#e6fcf5',
            '#c3fae8',
            '#96f2d7',
            '#63e6be',
            '#38d9a9',
            '#20c997',
            '#12b886',
            '#0ca678',
            '#099268',
            '#167e59',
          ],
        },
        headings: {
          fontWeight: 'bold',
          sizes: {
            h1: {
              fontSize: 30,
            },
          },
        },
        components: {
          DatePicker: {
            defaultProps: {
              locale: 'fr',
              inputFormat: 'DD/MM/YYYY',
              size: 'md',
            },
          },
          Paper: {
            styles: theme => ({
              root: {
                border: `1px solid ${theme.colors.gray[3]}`,
                borderRadius: 16,
                padding: '27px 38px 48px 36px',
              },
            }),
          },
          InputWrapper: {
            styles: theme => ({
              label: {
                fontWeight: theme.other.fontWeights.semiBold,
                paddingBottom: 4,
                fontSize: theme.fontSizes.md,
              },
              required: {
                color: theme.colors.gray[9],
              },
            }),
          },
          Modal: {
            defaultProps: {
              overlayColor: 'grey',
              overlayOpacity: 0.5,
              overlayBlur: 1,
            },
            styles: theme => ({
              modal: {
                padding: '0 !important',
              },
              close: {
                width: 46,
                height: 46,
                marginBottom: 'auto',
                background: theme.colors.gray[0],

                svg: {
                  height: 'auto',
                  width: 40,
                  color: theme.colors.dark[3],
                },
              },
              title: {
                paddingTop: 25,
              },
              header: {
                borderBottom: `1px solid ${theme.colors.gray[3]}`,
                padding: '30px 0 15px',
                margin: '0 32px 25px',
              },
            }),
          },
          Select: {
            defaultProps: {
              size: 'md',
              searchable: true,
            },
          },
          NumberInput: {
            defaultProps: {
              size: 'md',
            },
          },
          Badge: {
            styles: {
              inner: {
                textTransform: 'none',
              },
            },
          },
          MultiSelect: {
            defaultProps: {
              allowDeselect: false,
              size: 'md',
              searchable: true,
            },
            styles: theme => ({
              item: {
                '&:hover': {
                  background: theme.colors[theme.primaryColor][0],
                  color: theme.colors[theme.primaryColor],
                },
                '&:not(:hover)': {
                  background: `${theme.white} !important`,
                },
              },
              value: {
                background: theme.colors.gray[3],
              },
            }),
          },
          Input: {
            defaultProps: {
              radius: 'md',
            },
            styles: theme => ({
              input: {
                background: theme.colors.gray[1],
                border: `1px solid ${theme.colors.gray[1]}`,
                '::placeholder': {
                  color: theme.colors.gray[5],
                },
                '&:focus': {
                  borderColor: theme.colors[theme.primaryColor][5],
                },
                '&:focus-within': {
                  borderColor: theme.colors[theme.primaryColor][5],
                },
              },
              error: {
                borderColor: theme.colors.red[7],
              },
            }),
          },
          TextInput: {
            defaultProps: {
              size: 'md',
            },
          },
          PasswordInput: {
            defaultProps: {
              size: 'md',
            },
          },
          ColorInput: {
            defaultProps: {
              size: 'md',
            },
            styles: {
              input: {
                textTransform: 'uppercase',
                '::placeholder': {
                  textTransform: 'none',
                },
              },
            },
          },
          Button: {
            defaultProps: {
              radius: 'md',
            },
          },
          Anchor: {
            defaultProps: {
              color: 'dark.4',
            },
            styles: theme => ({
              root: {
                ':hover': {
                  textDecorationColor: theme.colors.gray[4],
                },
              },
            }),
          },
        },
        other: {
          fontSizes: {
            xsss: 8,
            xss: 10,
          },
          fontWeights: {
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
          },
        },
      }}
      withNormalizeCSS
      withGlobalStyles
    >
      <NotificationsProvider position="top-right">{children}</NotificationsProvider>
    </MantineProvider>
  );
};

export default MantineTheme;
