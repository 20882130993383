import { createStyles } from '@mantine/core';
import { rgba } from 'polished';
import styled from '@emotion/styled';

const styles = createStyles(theme => ({
  header: {
    background: theme.colors[theme.primaryColor],
    padding: '23px 20px 10px',
    a: {
      display: 'flex',
    },
  },

  content: {
    background: theme.colors[theme.primaryColor],
  },

  footer: {
    padding: '0 10px',
    background: theme.colors[theme.primaryColor],
    lineHeight: 1.55,
    fontWeight: theme.other.fontWeights.semiBold,

    button: {
      lineHeight: 'inherit',
      fontWeight: 'inherit',
      fontSize: theme.fontSizes.sm,
    },

    '> button': {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'auto 1fr ',
      gridColumnGap: 10,
      alignItems: 'center',
      padding: '20px 11px',
      borderTop: `1px solid ${theme.colors.gray[2]}`,

      div: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));

export const NavEntries = styled.div`
  padding: 10px;
  margin: 0;
  list-style: none;
  font-size: 15px;

  li {
    a {
      display: flex;
      align-items: center;
      padding: 12px 12px 12px 10px;
      color: ${({ theme }) => theme.white};
      border-radius: 6px;
      background: transparent;
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &:hover {
        background: ${({ theme }) => rgba(theme.white, 0.1)};
      }
      &.active {
        background: ${({ theme }) => rgba(theme.white, 0.2)};
      }
    }

    > span {
      display: flex;
      padding: 12px 12px 12px 10px;
      color: ${({ theme }) => theme.white};
      font-weight: ${({ theme }) => theme.other.fontWeights.bold};
    }
  }
`;

export default styles;
