import { lazy, ComponentType } from 'react';

export function safeLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy<T>(() =>
    factory().catch(() => {
      window.location.reload();

      return new Promise(resolve => {
        setTimeout(resolve, 3000);
      });
    }),
  );
}
