import React, { FC } from 'react';

import fr from 'date-fns/locale/fr';

import {
  DatePicker as ReactDatePicker,
  DatePickerInputProps as ReactDatePickerInputProps,
  DatePickerProps,
  DateRangePicker as ReactDateRangePicker,
  DateRangePickerProps,
} from 'react-next-dates';

import { DateFormat } from '@shared/modules/dates';

export const DatePicker: FC<Omit<DatePickerProps, 'locale'>> = ({
  format,
  placeholder,
  portalContainer,
  children,
  ...rest
}) => (
  <ReactDatePicker
    locale={fr}
    format={format ?? DateFormat.Local}
    placeholder={placeholder ?? 'JJ/MM/AAAA'}
    portalContainer={portalContainer ?? document.body}
    {...rest}>
    {children}
  </ReactDatePicker>
);

export const DateRangePicker: FC<Omit<DateRangePickerProps, 'locale'>> = ({
  format,
  startDatePlaceholder,
  endDatePlaceholder,
  portalContainer,
  children,
  ...rest
}) => (
  <ReactDateRangePicker
    locale={fr}
    format={format ?? DateFormat.Local}
    startDatePlaceholder={startDatePlaceholder ?? 'JJ/MM/AAAA'}
    endDatePlaceholder={endDatePlaceholder ?? 'JJ/MM/AAAA'}
    portalContainer={portalContainer ?? document.body}
    {...rest}>
    {children}
  </ReactDateRangePicker>
);

interface DatePickerInputProps extends ReactDatePickerInputProps {
  onClear?: () => void;
}

// FIXME Ajouter le clear avec mantine sinon supprimer
export const DatePickerInput = React.forwardRef<HTMLInputElement, DatePickerInputProps>(
  ({ onClear, ...props }, ref) => (
    <div className="ui input icon">
      <input {...props} ref={ref} />
    </div>
  ),
);
