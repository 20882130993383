import logo from '@assets/logos/logo.svg';
import { useAuthContext } from '@modules/auth/context';
import { sequenceT } from 'fp-ts/Apply';
import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import React, { FC, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles, * as Styled from './Nav.styles';
import { Avatar, Image, Menu, Navbar, ScrollArea, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import {
  Adjustments,
  ArrowsDoubleNeSw,
  Bell,
  Building,
  BuildingCommunity,
  DeviceDesktopAnalytics,
  Door,
  Home,
  Home2,
  Key,
  License,
  Logout,
  MapPin,
  MessageDots,
  User,
  UserCheck,
  Users,
} from 'tabler-icons-react';
import { useStyles } from '@styles/hooks';
import { Profile } from '@modules/profile/model';
import { renderNullable } from '@shared/utils/render';
import * as RA from 'fp-ts/ReadonlyArray';
import { Predicate } from 'fp-ts/Predicate';
import { userRolePredicate } from '@modules/auth/utils';
import { AdminUserRole } from '@modules/users/model';
import userHardHatIcon from '@assets/icons/user-hard-hat.svg';

interface NavEntry {
  title: string;
  to?: string;
  icon?: ReactNode;
  show?: Predicate<Profile>;
}

const navEntries: Array<NavEntry> = [
  {
    title: 'Tableau de bord',
    to: '/',
    icon: <Home />,
    show: userRolePredicate(AdminUserRole.Admin, AdminUserRole.HotLiner),
  },
  { title: 'Gestion' },
  {
    title: 'Clients',
    to: '/customers',
    icon: <User />,
    show: userRolePredicate(AdminUserRole.Admin, AdminUserRole.HotLiner),
  },
  { title: 'Agences', to: '/customer-agencies', icon: <Home2 /> },
  { title: 'Sites', to: '/housing-sites', icon: <MapPin /> },
  { title: 'Bâtiments', to: '/housing-buildings', icon: <Building /> },
  { title: 'Lots', to: '/housings', icon: <BuildingCommunity /> },
  { title: 'Portes', to: '/doors', icon: <Door /> },
  { title: 'Membres', to: '/occupants', icon: <Users /> },
  { title: 'Infos résident', to: '/alerts', icon: <Bell /> },
  { title: 'Accessoires', to: '/accessories', icon: <Key /> },
  {
    title: 'Demandes',
    to: '/accessory-requests',
    icon: <MessageDots />,
    show: userRolePredicate(AdminUserRole.Admin, AdminUserRole.HotLiner),
  },
  { title: 'Pass' },
  {
    title: 'Utilisateurs pass',
    to: '/customer-users',
    icon: <img src={userHardHatIcon} alt="Utilisateurs pass" width={14} />,
  },
  { title: 'Autorisations pass', to: '/passes', icon: <Adjustments /> },
  { title: 'Administration', show: userRolePredicate(AdminUserRole.Admin) },
  { title: 'Utilisateurs BO', to: '/users', icon: <UserCheck />, show: userRolePredicate(AdminUserRole.Admin) },
  {
    title: 'Supervision',
    to: '/supervision',
    icon: <DeviceDesktopAnalytics />,
    show: userRolePredicate(AdminUserRole.Admin, AdminUserRole.HotLiner),
  },
  {
    title: 'Accès API',
    to: '/partners',
    icon: <ArrowsDoubleNeSw />,
    show: userRolePredicate(AdminUserRole.Admin),
  },
];

function getNullableName(firstName: string | null, lastName: string | null, fallback: string): string {
  return pipe(
    sequenceT(O.Apply)(O.fromNullable(firstName), O.fromNullable(lastName)),
    O.fold(
      () => fallback,
      ([firstName, lastName]) => `${firstName} ${lastName}`,
    ),
  );
}

function getProfileName(profile: Profile): string {
  return getNullableName(profile.firstName, profile.lastName, 'Admin');
}

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const { logout } = useAuthContext();

  const { classes } = useStyles(styles, Nav, undefined);

  const name = getProfileName(profile);

  const firstLetters = pipe(
    name,
    S.split(' '),
    RA.takeLeft(2),
    RA.foldMap(S.Monoid)(flow(S.slice(0, 1), S.toUpperCase)),
  );

  return (
    <Navbar width={{ base: 270 }} zIndex={100}>
      <Navbar.Section className={classes.header}>
        <Link to="/">
          <Image src={logo} alt="Hapiix" width={87} />
        </Link>
      </Navbar.Section>

      <Navbar.Section grow className={classes.content} component={ScrollArea}>
        <Styled.NavEntries>
          {pipe(
            navEntries,
            A.filter(({ show }) => !show || show(profile)),
            A.mapWithIndex((j, entry) => (
              <li key={j}>
                {renderNullable(
                  entry.to,
                  to => (
                    <NavLink to={to} className={({ isActive }) => (isActive ? ' active' : '')}>
                      {renderNullable(entry.icon, icon => (
                        <ThemeIcon variant="light" color="teal.6" p={4} mr="xl">
                          {icon}
                        </ThemeIcon>
                      ))}
                      <div>
                        <span>{entry.title}</span>
                      </div>
                    </NavLink>
                  ),
                  () => (
                    <span>{entry.title}</span>
                  ),
                )}
              </li>
            )),
          )}
        </Styled.NavEntries>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Menu radius={16} offset={-10} withArrow>
          <Menu.Target>
            <UnstyledButton p="md">
              <Avatar radius="xl" color="tean">
                {firstLetters}
              </Avatar>
              <Text size="sm" color="white">
                {name}
              </Text>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown px={6} py={8}>
            <Menu.Item component={Link} to="/profile" icon={<User size={14} />}>
              Mon compte
            </Menu.Item>
            <Menu.Item
              component="a"
              href="https://www.hapiix.com/mentions-legales"
              target="_blank"
              rel="noopener noreferrer"
              icon={<License size={14} />}
            >
              Mentions légales
            </Menu.Item>
            <Menu.Item
              component="a"
              href="https://www.hapiix.com/cgu-back-office"
              target="_blank"
              rel="noopener noreferrer"
              icon={<License size={14} />}
            >
              CGU
            </Menu.Item>
            <Menu.Item icon={<Logout size={14} />} onClick={logout}>
              Se déconnecter
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Navbar.Section>
    </Navbar>
  );
};

export default Nav;
