import React, { FC, useEffect, useState } from 'react';
import { keyframes } from '@mantine/core';
import styled from '@emotion/styled';

const loadingAnimation = keyframes({
  from: { left: '-200px', width: '30%' },
  '50%': { width: '30%' },
  '70%': { width: '70%' },
  '80%': { left: '50%' },
  '95%': { left: '120%' },
  to: { left: '100%' },
});

export const LineLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors[theme.primaryColor][5]};
    animation: ${loadingAnimation} 2s linear infinite;
  }
`;

interface DebouncedLineLoaderProps {
  timeout?: number;
  className?: string;
}

export const DebouncedLineLoader: FC<DebouncedLineLoaderProps> = ({ timeout, className }) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShow(true);
    }, timeout ?? 500);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [timeout]);

  return show ? <LineLoader className={className} /> : null;
};

export default LineLoader;
