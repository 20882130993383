import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import Seo from '@shared/modules/seo/Seo';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink, To, useLocation } from 'react-router-dom';
import * as Styled from './Page.styles';
import { Anchor, Breadcrumbs, Group, Text, Title } from '@mantine/core';
import { ChevronRight } from 'tabler-icons-react';
import { isSamePathname } from '@layout/utils';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: To;
  exact?: boolean;
}

export interface Breadcrumb {
  title: ReactNode;
  displayTitle?: string;
  to?: To;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  title?: ReactNode;
  loading?: boolean;
}

const PageTabs: FC<{ tabs?: Array<Tab> }> = ({ tabs }) => {
  if (tabs && tabs.length) {
    return (
      <Group grow sx={theme => ({ backgroundColor: theme.white })}>
        <nav>
          <Styled.PageTabs>
            {tabs.map((tab, i) => (
              <li key={i}>
                <Anchor component={NavLink} to={tab.to} size="sm" color="gray.6" end>
                  {tab.title}
                </Anchor>
              </li>
            ))}
          </Styled.PageTabs>
        </nav>
      </Group>
    );
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottom>
        <div>
          <div className="left">{O.toNullable(leftContent)}</div>
          <div className="right">{O.toNullable(rightContent)}</div>
        </div>
      </Styled.PageBottom>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ title, breadcrumbs, tabs, bottom, loading, children }) => {
  const location = useLocation();
  const breadcrumbsOpt = pipe(
    O.fromNullable(breadcrumbs),
    O.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const seoTitle: string | undefined = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs => breadcrumbs.map(b => b.displayTitle ?? b.title).join(' - ')),
    O.toUndefined,
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {(title || O.isSome(breadcrumbsOpt)) && (
        <Styled.PageTop>
          {renderNullable(title, title => (
            <Title color="dark.6">{title}</Title>
          ))}
          {renderOptional(breadcrumbsOpt, breadcrumbs => (
            <Styled.PageContentTopLeft>
              <Breadcrumbs separator={<ChevronRight size={20} />} style={{ alignItems: 'center', padding: '8px 0' }}>
                {breadcrumbs.map((item, i) =>
                  item.to && !isSamePathname(item.to, location) ? (
                    <Anchor key={i} component={Link} to={item.to} weight="500">
                      {item.title}
                    </Anchor>
                  ) : (
                    <Text key={i}>{item.title}</Text>
                  ),
                )}
              </Breadcrumbs>
            </Styled.PageContentTopLeft>
          ))}
        </Styled.PageTop>
      )}

      {loading && <DebouncedLineLoader />}

      <PageTabs tabs={tabs} />

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent>
          <Styled.PageChildren>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
