import styled from '@emotion/styled';

export const PageTabs = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  padding-inline-start: 16px;
  margin: 0;
  height: 46px;
  ms-overflow-style: -ms-autohiding-scrollbar;

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.gray[2]};
  }

  > li {
    height: 100%;

    > a {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 5px 16px 0;
      font-weight: ${({ theme }) => theme.other.fontWeights.semiBold};

      &.active {
        color: ${({ theme }) => theme.colors[theme.primaryColor][9]};

        &:after {
          display: block;
          content: '';
          position: absolute;
          z-index: 5;
          left: 0;
          bottom: 1px;
          width: 100%;
          height: 4px;
          background: ${({ theme }) => theme.colors[theme.primaryColor][9]};
          border-radius: 4px;
        }
      }
    }
  }
`;

export const PageBottom = styled.div`
  min-height: 61px;
  background: ${({ theme }) => theme.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray[2]};

  > div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;

    > div {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      padding: 7px 0;

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
`;

export const PageTop = styled.div`
  display: grid;
  align-items: center;
  min-height: 76px;
  padding: 15px 30px;
  flex: 0 0 auto;
  background: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[2]};

  a {
    color: ${({ theme }) => theme.colors.blue[5]};
    :hover {
      text-decoration-color: ${({ theme }) => theme.colors.blue[1]};
    }
  }
`;

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
`;

export const PageContentTopLeft = styled.div`
  flex: 1 1 auto;
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  padding-bottom: 20px;
`;
