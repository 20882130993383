import Nav from '@layout/nav/Nav';
import React, { FC, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { Profile } from '@modules/profile/model';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import * as Sentry from '@sentry/react';

interface LayoutProps {
  profile: Profile | null;
}

const Layout: FC<LayoutProps> = ({ profile }) => {
  const location = useLocation();

  if (profile == null) {
    return <Navigate to="/login" replace state={{ referrer: location }} />;
  }

  return (
    <>
      <Styled.LayoutContainer>
        <Nav profile={profile} />

        <Styled.LayoutContent>
          <Suspense fallback={<DebouncedLineLoader />}>
            <Sentry.ErrorBoundary fallback={<ErrorPage error={HttpError.default} />}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </Suspense>
        </Styled.LayoutContent>
      </Styled.LayoutContainer>
    </>
  );
};

export default Layout;
