import React, { FC, ForwardedRef, forwardRef, ReactNode, useEffect } from 'react';

import * as Styled from './VirtualizedTable.styles';
import { Link } from 'react-router-dom';
import {
  RangeCursor,
  VirtualizedTableProps,
  VirtualizedTableRowClickHandler,
  VirtualizedTableRowLinkBuilder,
} from '@shared/modules/range/model';
import { renderNullable, renderOptional } from '@shared/utils/render';

import { Box, Skeleton, Stack, Text } from '@mantine/core';

import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { useThrottledCallback } from 'use-debounce';

import * as O from 'fp-ts/Option';
import { PAGE_SCROLLER_ID } from '@layout/page/Page';
import { constant } from 'fp-ts/function';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';

interface LoadingRowProps {
  index: number;
  loadRow: (index: number) => void;
}

const LoadingRow: FC<LoadingRowProps> = ({ index, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return (
    <Stack p="xs" spacing={6}>
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} radius="xl" width="95%" />
      <Skeleton height={8} radius="xl" width="85%" />
    </Stack>
  );
};

interface VirtualizedRowProps<T> {
  row: VirtualItem;
  item: O.Option<T>;
  loadRow: (index: number) => void;
  rowLinkBuilder?: VirtualizedTableRowLinkBuilder<T>;
  onRowClick?: VirtualizedTableRowClickHandler<T>;
  children: (item: T, index: number) => ReactNode;
}

function VirtualizedRowInner<T>(
  { row, item, loadRow, rowLinkBuilder, onRowClick, children }: VirtualizedRowProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handleClick = (item: T) => () => onRowClick?.(item, row.index);

  return (
    <Styled.VirtualizedTableRowWrapper
      data-index={row.index}
      ref={ref}
      style={{
        minHeight: row.size,
        transform: `translateY(${row.start}px)`,
      }}
      className={rowLinkBuilder || onRowClick ? 'with-hover' : undefined}
    >
      {renderOptional(
        item,
        item =>
          renderNullable(
            rowLinkBuilder?.(item, row.index),
            to => (
              <Box component={Link} to={to} onClick={handleClick(item)} td="none">
                {children(item, row.index)}
              </Box>
            ),
            () => (
              <Box ref={ref} onClick={handleClick(item)}>
                {children(item, row.index)}
              </Box>
            ),
          ),
        () => (
          <LoadingRow index={row.index} loadRow={loadRow} />
        ),
      )}
    </Styled.VirtualizedTableRowWrapper>
  );
}

const VirtualizedRow = forwardRef(VirtualizedRowInner);

export function VirtualizedTable<T>({
  range,
  children,
  loadMore,
  noFilter,
  header,
  estimatedRowHeight = 60,
  emptyMessage = 'Aucune donnée à afficher.',
  rowLinkBuilder,
  onRowClick,
}: VirtualizedTableProps<T>) {
  useEffect(() => {
    if (noFilter) {
      loadMore(RangeCursor.initial());
    }
  }, [loadMore, noFilter]);

  const virtualizer = useVirtualizer({
    count: range.total,
    estimateSize: constant(estimatedRowHeight),
    overscan: 20,
    getScrollElement: constant(document.getElementById(PAGE_SCROLLER_ID)),
  });

  useEffect(() => {
    virtualizer.scrollToIndex(0);
    virtualizer.measure();
  }, [range.total, virtualizer]);

  const handleLoadRow = (index: number) => {
    if (!range.loading && !range.has(index)) {
      const page = Math.floor(index / RangeCursor.DEFAULT_SIZE);

      loadMore(RangeCursor.fromPage(page));
    }
  };

  // Throttle to wait state refresh
  const throttleLoadRow = useThrottledCallback(handleLoadRow, 100);

  return (
    <div>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 10 }}>{header}</Box>

      {range.loading && <DebouncedLineLoader />}

      <Styled.VirtualizedTableList style={{ height: virtualizer.getTotalSize() }}>
        {range.total === 0 && !range.loading ? (
          <Styled.VirtualizedTableNoRow>
            <Text size="sm" color="gray.8" px={43} py="lg" bg="white" italic>
              {emptyMessage}
            </Text>
          </Styled.VirtualizedTableNoRow>
        ) : (
          virtualizer
            .getVirtualItems()
            .map(row => (
              <VirtualizedRow
                key={row.key}
                row={row}
                ref={virtualizer.measureElement}
                item={range.get(row.index)}
                loadRow={throttleLoadRow}
                rowLinkBuilder={rowLinkBuilder}
                onRowClick={onRowClick}
                children={children}
              />
            ))
        )}
      </Styled.VirtualizedTableList>
    </div>
  );
}
