import React, { FC } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { Link } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  return (
    <Styled.ErrorPageContainer>
      <Title>
        {HttpStatusCode.NOT_FOUND === error.status
          ? 'La page que vous cherchez est introuvable'
          : `Une erreur ${error.status} est survenue`}
      </Title>

      <Text p="30px 0">Nous vous invitons à revenir à l'accueil</Text>

      <Button component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
