import { MantineTheme, UseStylesOptions } from '@mantine/core';

export function useStyles<Key extends string, Params>(
  styles: (
    params: Params,
    options?: UseStylesOptions<Key>,
  ) => {
    classes: Record<Key, string>;
    cx: (...args: any) => string;
    theme: MantineTheme;
  },
  component: any,
  params: Params,
  options?: UseStylesOptions<Key>,
) {
  return styles(params, { name: import.meta.env.DEV ? component.name : '', ...options });
}
