import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

interface SeoProps {
  title?: string;
}

const baseTitle = import.meta.env.VITE_APP_TITLE;

const Seo: FC<SeoProps> = ({ title }) => {
  const metaTitle = pipe(
    O.fromNullable(title),
    O.fold(
      () => baseTitle,
      title => `${baseTitle} - ${title}`,
    ),
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default Seo;
