import * as O from 'fp-ts/Option';
import * as RD from 'fp-ts-remote-data';
import { PageProps } from '@layout/page/Page';
import { pipe } from 'fp-ts/function';
import { parsePath, To } from 'history';
import * as S from 'fp-ts/string';

export function getPagePropsFromOptional<T>(opt: O.Option<T>, onSome: (value: T) => PageProps): PageProps {
  return pipe(
    opt,
    O.fold(() => ({}), onSome),
  );
}

export function getPagePropsFromRemoteData<E, T>(
  remoteData: RD.RemoteData<E, T>,
  onSuccess: (value: T) => PageProps,
): PageProps {
  return getPagePropsFromOptional(RD.getSuccess(remoteData), onSuccess);
}

export function getPathname(to: To) {
  return S.isString(to) ? parsePath(to).pathname : to.pathname;
}
export function isSamePathname(x: To, y: To) {
  return getPathname(x) === getPathname(y);
}
