import styled from '@emotion/styled';

export const LayoutContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;
`;

export const LayoutContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;
