import styled from '@emotion/styled';

export const VirtualizedTableRowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  transition: background 0.15s linear;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 43px;
    right: 49px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[3]};
    z-index: -1;
  }

  &:last-child {
    border-radius: 0 0 16px 16px;
    overflow: hidden;

    > a > div {
      border-radius: 0 0 16px 16px;
      overflow: hidden;
    }
  }

  &.with-hover {
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.gray[0]};
    }
  }

  > div,
  > a {
    width: 100%;
  }
`;

export const VirtualizedTableNoRow = styled(VirtualizedTableRowWrapper)`
  will-change: unset;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 0 1px 1px ${({ theme }) => theme.colors.gray[3]};
`;

export const VirtualizedTableList = styled.div`
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-right: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-radius: 0 0 16px 16px;
  background: ${({ theme }) => theme.white};
`;
